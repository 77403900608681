import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Faktory ovlivňující rozšíření druhů" />
    <h1>Faktory ovlivňující rozšíření druhů</h1>
<p>Mezi hlavn&iacute; faktory ovlivňuj&iacute;c&iacute; roz&scaron;&iacute;řen&iacute; druhu z pohledu <Link to="/biogeografie/areografie/">areografie</Link>, tedy zejm&eacute;na velikost, tvar a obsazenost are&aacute;lu dan&yacute;m druhem, patř&iacute;:</p>
<ul>
<li>&scaron;&iacute;řka niky</li>
<li>pozice niky</li>
<li>abundance (lok&aacute;ln&iacute; četnost)</li>
<li>schopnost disperze <em>(nen&iacute; rozeps&aacute;no n&iacute;že)</em></li>
<li>velikost &uacute;zem&iacute; možn&eacute;ho k os&iacute;dlen&iacute; <em>(nen&iacute; rozeps&aacute;n&iacute; n&iacute;že)</em></li>
</ul>
<hr />
<h2>Co nejvíce ovlivňuje areál druhu?</h2>
<h3>&Scaron;&iacute;řka niky</h3>
<p>&Scaron;&iacute;řka <Link to="/biogeografie/ekologicka/ekologicke-faktory/">ekologick&eacute; niky</Link> je důležit&yacute; faktor ovlivňuj&iacute;c&iacute; roz&scaron;&iacute;řen&iacute; druhu. Druhy, kter&eacute; maj&iacute; ekologickou niku &scaron;ir&scaron;&iacute;, maj&iacute; v&yacute;hodu, os&iacute;dluj&iacute; různ&eacute; typy prostřed&iacute; a zpravidla vykazuj&iacute; vysok&eacute; lok&aacute;ln&iacute; populačn&iacute; hodnoty.</p>
<h3>Pozice niky</h3>
<p>I druhy, kter&eacute; jsou &uacute;zce specializovan&eacute; na jeden zdroj a maj&iacute; tak ekologickou niku &uacute;zkou, mohou b&yacute;t &scaron;iroce roz&scaron;&iacute;řen&eacute; a m&iacute;t vysok&eacute; populačn&iacute; hodnoty. To je d&aacute;no pozic&iacute; (nebo "polohou") niky, tedy t&iacute;m, na jak&yacute; zdroj je druh specializovan&yacute;. Vz&aacute;cnost dan&eacute;ho zdroje pak velmi ovlivňuje roz&scaron;&iacute;řen&iacute; druhu.</p>
<h3>Abundance (lok&aacute;ln&iacute; četnost)</h3>
<p>Př&iacute;tomnost druhu na lokalitě nemus&iacute; svědčit o vysok&eacute; vhodnosti a v&yacute;hodnosti prostřed&iacute;, může b&yacute;t totiž zapř&iacute;činěna vysokou abundanc&iacute; (četnost&iacute;) v nedalek&eacute; lokalitě, kter&aacute; je zdrojem přistěhovalců.</p>
<h4>Rescue effect</h4>
<p>Rescue effect je ekologick&yacute; proces, při němž doch&aacute;z&iacute; k rychl&eacute; rekolonizaci nebo k nahrazen&iacute; n&aacute;hodn&eacute;ho &uacute;bytku početnosti na v&scaron;ech lokalit&aacute;ch.</p>
<p>Rescue effect je možn&yacute; d&iacute;ky poč&aacute;tečn&iacute;mu os&iacute;dlen&iacute; velk&eacute;ho množstv&iacute; lokalit a vytvořen&iacute; siln&yacute;ch populac&iacute; (tzv. source populations) v těchto lokalit&aacute;ch. Z těchto siln&yacute;ch populac&iacute; pak migruj&iacute; jedinci do dal&scaron;&iacute;ch lokalit včetně těch s m&eacute;ně př&iacute;hodn&yacute;mi podm&iacute;nkami.</p>
<h4>Sink populations</h4>
<p>Sink populations je ekologick&yacute; jev, při němž počet migruj&iacute;c&iacute;ch jedinců už nestač&iacute; nahrazovat &uacute;bytek na ostatn&iacute;ch lokalit&aacute;ch a druh tak může z řady lokalit velmi rychle mizet.</p>
<hr />
<h2>J&aacute;drov&eacute; a satelitn&iacute; druhy</h2>
<p>J&aacute;drov&eacute; druhy (anglicky <em>core species</em>) jsou takov&eacute; druhy, kter&eacute; nejsou specializovan&eacute; či jsou specializovan&yacute; na hojn&yacute; zdroj. D&iacute;ky tomu maj&iacute; vysokou abundanci, množstv&iacute; migrantů a osidluj&iacute; hodně lokalit včetně těch s m&eacute;ně př&iacute;zniv&yacute;m prostřed&iacute;m.</p>
<p>Satelitn&iacute; druhy (anglicky <em>satellite species</em>) jsou druhy se specializac&iacute; na relativně vz&aacute;cn&eacute; druhy, d&iacute;ky čemuž maj&iacute; slab&eacute; populace, m&aacute;lo migrantů a vět&scaron;inou osidluj&iacute; je&scaron;tě m&eacute;ně lokalit než by jim př&iacute;znivost prostřed&iacute; umožňovala.</p>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Faktory ovlivňující rozšíření druhů</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/areografie/makroekologicke-vlastnosti-arealu/"><button className="inv">&larr; Makroekologické vlasntosti areálů</button></Link>
    <Link to="/biogeografie/areografie/typy-rozsireni/"><button className="inv">Typy rozšíření a areálů &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
